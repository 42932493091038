.about-container {
    padding: 2rem;
    background: #f5f5f5; 
    color: #333;
    max-width: 1000px;
    margin: 2rem auto 2rem;
    margin-bottom: 2rem;
}

.about-box, .description-box {
    padding: 1.5rem;
    background: #fff;
    color: #333;
    margin-bottom: 2rem;
    border-radius: 8px;
}

.about-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.profile-photo {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 1rem;
}

.text-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 2;
    margin-left: 1rem;
    margin-top: 1.5rem;
}

.text-content h1 {
    font-size: 2rem;
    font-weight: bold;
}

.text-content p {
    margin-bottom: 0.5rem;
}

.location {
    margin-bottom: 0.25rem;
}

.nav-links {
    display: flex;
    gap: 0.5rem;
}

@media (max-width: 600px) {
    .about-content {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .text-content {
        text-align: center;
        max-width: 100%;
        margin-left: 0;
    }

    .text-content h1 {
        font-size: 1.25rem;
    }

    .profile-photo {
        width: 200px;
        height: 200px;
        margin-bottom: -15px
    }

    .nav-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
    }
}
