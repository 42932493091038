.notes-container {
  padding: 20px;
  max-width: 800px;
  margin: 2rem auto;
  font-family: Arial, sans-serif;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.notes-container h1 {
  text-align: center;
}

/* Notes List */
.notes-list {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.note-card {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s, transform 0.3s;
  flex: 1 1 calc(33% - 20px);
  box-sizing: border-box;
}

.note-card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.note-title {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  text-align: center;
}

/* Note Details */
.note-details {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #f9f9f9;
  text-align: left;
}

.note-details h2 {
  margin-top: 0;
}

.note-actions {
  margin-top: 10px;
}

.edit-button,
.delete-button {
  margin: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.edit-button {
  background-color: #007bff;
}

.delete-button {
  background-color: #dc3545;
}

.cancel-button {
  background-color: #ffc107; 
  color: #fff;
  margin: 10px auto;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.cancel-button:hover {
  background-color: #e0a800; 
}

.close-button {
  background-color: #6c757d; 
  color: #fff; 
  margin: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #5a6268; 
}

/* Form Styles */
.note-form {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: left;
}

.note-title-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.rich-editor .ql-editor {
  background-color: #fff;
}

.note-form button,
.auth-section button {
  margin: 10px auto;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.note-form .submit-button {
  background-color: #28a745;
}

.note-form .submit-button:hover {
  background-color: #218838;
}

/* Authentication Section */
.auth-section {
  margin-top: 20px;
  text-align: center;
}

.auth-section h2 {
  margin-top: 0;
}

.auth-input {
  width: calc(100% - 40px);
  padding: 10px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.auth-button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.auth-button:hover {
  background-color: #0056b3;
}
