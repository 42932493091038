.contact-container {
  padding: 2rem;
  background: #fff;
  color: #333;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.intro-card {
  padding: 2rem;
  background: #f9f9f9;
  color: #333;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form {
  text-align: left;
}

.contact-form .MuiTextField-root {
  margin-bottom: 1rem;
}

.same-size-textbox {
  width: 100%; 
}

.submit-button {
  margin-top: 1rem;
  background-color: #007bff;
  color: #fff;
}

.submit-button:hover {
  background-color: #0056b3;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #eaeaea;
  margin: 0;
}

.MuiAppBar-root {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

main {
  display: block;
  transition: margin-left 0.3s ease-in-out; 
}

.notes-textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.notes-select {
  margin-right: 10px;
}

.notes-button {
  margin: 5px;
}

.experience {
  padding: 20px;
}

.vertical-timeline-element-title {
  font-size: 24px;
  font-weight: bold;
}

.timeline-date {
  font-weight: bold;
}

.timeline-text {
  margin-bottom: 1rem; 
}

.show-more-button {
  display: block;
  margin-top: 1rem; 
  background-color: #3e497a;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.show-more-button:hover {
  background-color: #2c3e50;
}

@media (max-width: 600px) {
  .MuiAppBar-root {
    display: flex;
    flex-direction: column;
  }

  .MuiAppBar-root .MuiToolbar-root {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .MuiAppBar-root .MuiButton-root {
    display: none; 
  }

  .MuiDrawer-root {
    display: block;
  }

  .MuiDrawer-paper {
    width: 250px; 
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }

  .MuiDrawer-root .MuiDrawer-paper {
    transform: translateX(-250px); 
  }

  .MuiDrawer-root.MuiDrawer-docked .MuiDrawer-paper {
    transform: translateX(0); 
  }
}